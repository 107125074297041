import React from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  Container,
  Section
} from 'sqrl-design-system'
import SEO from '../components/SEO'

export default () => (
  <div style={{ backgroundColor: '#f5f5f5' }}>
    <SEO title="Wellness Welcome" />
    <Section>
      <Container>
        <Card
          style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}
        >
          <CardHeader style={{ padding: 20 }}>
            <strong>SQRL | Workforce</strong>
          </CardHeader>
          <CardContent>
            <h1 style={{ marginBottom: 30 }}>Wellness at your work</h1>
            <p className="typography medium">
              Heya Tom,
              <br />
              Lorem Ipsum Dolor
              <br />
            </p>
            <Button
              className="is-rounded"
              isColor="primary"
              isSize="large"
              style={{ marginTop: 20, marginBottom: 20, width: 320 }}
            >
              Confirm Email
            </Button>
            <p className="typography medium">
              P.S. If you don't want to hear from me again,{' '}
              <a href="">please let me know</a>.
            </p>
          </CardContent>
          <CardFooter style={{ padding: 20 }}>
            Available now for iOS and Android
          </CardFooter>
        </Card>
      </Container>
    </Section>
  </div>
)
